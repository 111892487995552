.container {
	color: #fff;
}

.watchList_wrapper {
	height: 48vh;
	margin-top: 52vh;
	margin-left: 4vw;
	overflow: hidden;
	position: relative;

	.watchList {
		transition: transform 0.2s ease-in-out;
	}
}

.no_content_container {
	display: -ms-flexbox;
	display: flex;
	height: 100vh;
	z-index: 2;
	-ms-transform: translatey(0%);
	transform: translatey(0%);
	/* width: calc(100% - 13.96vw); */
	width: calc(100%);
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-direction: column;
	flex-direction: column;
	-ms-flex-pack: center;
	justify-content: center;
	-ms-flex-negative: 0;
	flex-shrink: 0;
}

.no_content_image {
	display: block;
	height: 6.51vw;
	width: 6.51vw;
	margin: -6.25vw auto 0 auto;
}

.no_content_title {
	color: #d4d4d4;
	font-size: 1.86vw;
	margin-top: 3.39vw;
}
