.grid_wrapper {
	display: block;
	overflow-y: scroll;
	padding-left: 3.727vw;
	padding-right: 3.125vw;
	padding-top: 1vw;
	position: relative;
	width: 100vw;

	.grid {
		align-items: center;
		display: block;
		flex-wrap: wrap;
		transition: transform 0.2s ease-in-out;

		> div {
			margin: 0.415vw;
		}
	}
}
