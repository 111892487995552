.trythisWrap {
	position: relative;
	bottom: 3.54vw;
}

.noWatchlist {
	top: 2.08vw;
}

.synopsis_wrap {
	margin-left: 3vw;
	color: #fff;
	width: 72.92vw;
	height: 7.03vw;
}

.heading {
	color: #f1f1f1;
	font-size: 1.66vw;
	font-weight: 700;
	letter-spacing: 0;
	margin-bottom: 0.78vw;
}

.rating {
	color: #9b9b9b;
	font-size: 1.14vw;
	letter-spacing: 0;
	margin-left: 1.46vw;
}

.synopsis {
	letter-spacing: 0;
	line-height: 1.5;
	font-size: 1.46vw;
	display: -webkit-box;
	overflow: hidden;
	text-overflow: ellipsis;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
}
