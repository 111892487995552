.nav-item {
	font-family: 'Roboto Medium', 'Roboto', sans-serif;
	background-color: #0f0f0f;
	color: rgba(241, 241, 241, 0.6);
	font-size: 1.46vw;
	padding: 1.56vw 3vw;
	text-decoration: none;
	width: 100%;
	border-bottom: 0.16vw solid transparent;
	display: flex;
	flex-direction: column;

	span {
		font-size: 1.04vw;
		margin-top: 0.31vw;
	}

	&.focus {
		font-family: 'Roboto Bold', 'Roboto', sans-serif;
		background-color: #1e1e1e;
		border-bottom: 0.16vw solid #03ffeb;
		color: #f1f1f1;
	}

	&.active {
		font-family: 'Roboto Bold', 'Roboto', sans-serif;
		background-color: #1e1e1e;
	}
}

// .section div {
// 	padding: 3.54vw 3.33vw 3.54vw 1.88vw !important;
// }
