.timeline {
	height: 4.01vw;
	position: relative;

	div,
	span {
		color: #808080;
		font-size: 1.35vw;
		font-weight: 500;
		float: left;
	}

	.current_time {
		color: #00ffeb;
		display: block;
		position: absolute;
		left: 15.63vw;
		z-index: 10;
		transition: left ease-in-out 0.1s;

		&::after {
			background-color: #00ffeb;
			content: '';
			height: 100vw;
			width: 0.26vw;
			display: block;
		}
	}
}
