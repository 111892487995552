.dialog_buttons_group{
    display: flex;

    &.horizontal{
        > button{
            margin-right: 1.5vw;
        
            &:last-child{
                margin-right: 0;
            }
        }
    }

    &.vertical{
        align-items: center;
        flex-direction: column;
        justify-content: center;

        > button{
            margin-bottom: 1.5vw;

            &:last-child{
                margin-bottom: 0;
            }
        }
    }
}