.keyboard_wrapper {
	display: flex;

	.keyboard_keys {
		height: 22.5vw;
		width: 20.57vw;
	}

	.action_buttons {
		display: flex;
		flex-direction: column;
		margin-left: 0.5vw;
	}
}
