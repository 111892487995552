
.toast {
    position: fixed;
    z-index: 15;
    align-items: center;
    justify-content: center;
    display: flex;
    top: 2.81vw;
    right: -8.33vw;
    width: auto;
    height: 4.95vw;
    line-height: 1.04vw;
    text-align: center;
    background: rgba(0,0,0,0.7);
    opacity: 0;
    padding: 0 1.56vw;

    &.show {
        animation: slideLeft 750ms 1 normal cubic-bezier(0.22, 1, 0.36, 1) forwards;
    }

    &.hide {
        animation: slideRight 1s 1 normal cubic-bezier(0.25, 1, 0.5, 1) forwards;
    }

    p {
        font-weight: bold;
        font-size: 1.56vw;
        padding:0 0.1vw 0 1.5vw;
    }
}

@keyframes slideLeft {
    0% {
        opacity: 0;
        transform: translate3d(0, 0, 0);
    }

    100% {
        opacity: 1;
        transform: translate3d(-11.25vw, 0, 0);
    }
}

@keyframes slideRight {
    0% {
        opacity: 1;
        transform: translate3d(-11.25vw, 0, 0);
    }

    100% {
        opacity: 0;
        transform: translate3d(0, 0, 0);
    }
}