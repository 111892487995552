.dstv_logo {
	text-align: right;
	width: 5.73vw;
	height:auto;
	position: fixed;
	top: 3.13vw;
	right: 3.13vw;
}
.container {
	align-items: center;
	display: flex;
	flex-direction: column;
	height: 100vh;
	justify-content: center;
	position: absolute;
	right: 0;
	width: 100vw;
	.name__delete {
		color: #f1f1f1;
		margin: 1.5vw auto 2vw auto;
		white-space: pre-wrap;
		font-size: 2.29vw;
	}
	.title {
		color: #f1f1f1;
		position: relative;
		text-align: center;
		height: 3.13vw;
		z-index: 2;
		font-size: 2.6vw;
		margin: 0vw 0vw 3vw 0;
	}
	.description {
		color: #f1f1f1;
		font-size: 1.67vw;
		line-height: 1.5;
		position: relative;
		text-align: center;
		white-space: pre-wrap;
		width: 41.25vw;
		z-index: 2;
		margin: 2.5vw auto 3vw auto;
	}
}
