.sidepanel_container {
	position: absolute;
	left: 0;
	top: 0;
	width: 93.66vw;
	height: 100vh;
	background-image: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);

	.sidepanel {
		position: absolute;
		right: 0;
		display: flex;
		flex-direction: column;
		justify-content: center;
		border-radius: 1.04vw 0px 0px 1.04vw;
		box-shadow: 0px 0.52vw 1.04vw 1.04vw rgba(0, 0, 0, 0.7);
		background-color: #0e0e0e;
		width: 30vw;
		height: 100vh;
	}
}
