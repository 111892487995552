.container {
	position: relative;
	margin: 1.2vw 1.667vw;
	width: 14.896vw;
	min-height: 15.885vw;
	background-color: transparent;
	filter: brightness(0.8);
	display: inline-block;
	border: 2px solid transparent;
	border-radius: 0.28vw;

	&.active {
		transform: scale3d(1.05, 1.05, 1.05);
		filter: brightness(1);
		border-color: var(--white-tv);
		.card {
			background-color: var(--grey12);

			.playButton {
				opacity: 1;
			}

			.meta {
				color: var(--white-tv);
			}
		}
	}

	.card {
		border-radius: 0.26vw;
		background-color: var(--grey16);

		header {
			display: flex;
			font-size: 1.2vw;
			padding: 0.415vw;
			text-align: right;
			justify-content: flex-end;

			> * {
				width: 33.33%;
			}

			> span:last-child {
				justify-content: flex-end;
			}
		}

		.overlay {
			position: absolute;
			top: 0;
			left: 0;
			min-height: 17.76vw;
			background: rgba(0, 0, 0, 0);
			transition: background 0.5s ease;
		}

		.image {
			width: 100%;
			height: 8.333vw;
			margin: 0;
			padding: 0;
		}

		span {
			display: flex;
			align-items: center;
			justify-content: center;

			.playButton {
				filter: drop-shadow(0vw 0.26vw 0.52vw rgba(0, 0, 0, 0.8));
				height: 6.04vw;
				opacity: 0;
				position: absolute;
				text-align: center;
				transition: opacity 0.35s ease;
				width: 6.04vw;
			}
		}

		.logo {
			height: 2.083vw;
			width: auto;
		}

		.meta {
			color: var(--grey7);
			padding: 0.415vw 0.78vw;

			.time {
				font-size: 1.15vw;
				margin-bottom: 0.26vw;
			}

			.title {
				font-size: 1.15vw;
				font-weight: bold;
				margin-bottom: 0.52vw;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
			}

			.progress_wrapper {
				height: 0.21vw;
			}
		}
	}
}
