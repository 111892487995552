.button {
	align-items: center;
	background: transparent;
	border-radius: 5.21vw;
	border: 0.15vw solid var(--white-tv);
	color: var(--white-tv);
	display: flex;
	font-size: 1.25vw;
	font-weight: 500;
	height: 3.54vw;
	min-width: 3.54vw;
	width: fit-content;

	img {
		width: 1.56vw;
		height: 1.56vw;
		margin-left: 1.56vw;
	}

	.invert {
		filter: invert(1) brightness(0);
	}

	span {
		padding: 0.83vw 1.56vw;
		width: 100%;
	}

	img + span {
		padding-left: 1.28vw;
	}

	img:last-child {
		margin: 0 auto;
	}

	&.active {
		background-color: #b50800;
		border-color: #b50800;
		color: white;
	}

	&.selected {
		background-color: #919191;
		color: black;
	}
}

.loader {
	width: 2.8vw;
	height: 2.8vw;
	margin-right: 1.28vw;
	border-radius: 25px;
}

.rotate {
	animation: rotation 1s infinite linear;
}

@keyframes rotation {
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(360deg);
	}
}

.back_to_live {
	align-items: center;
	width: 11vw;
	border: 0.15vw solid #767676;
	background: #767676;
	color: var(--white-tv);
	font-size: 1.2vw;
	font-weight: 500;
	height: 3.54vw;
	min-width: 3.54vw;
	border-radius: 0.5vw;
	padding-left: 1.5vw;
}

.active_back_to_live {
	background-color: #b50800;
	border-color: #b50800;
	color: white;
}
