.player_button {
	align-items: center;
	border-radius: 100vw;
	display: flex;
	font-size: 1.35vw;
	font-weight: bold;
	height: 5.21vw;
	justify-content: center;
	min-width: 5.21vw;
	opacity: 0.4;
	padding: 1.3vw 0.94vw;
	position: relative;

	img {
		width: 2.66vw;
	}

	.player_button_logo {
		width: 4.38vw;

		img {
			width: 100%;
		}
	}

	&.focus {
		background-color: #00ffeb;
		color: #000;
		opacity: 1;

		span {
			width: 0vw;
		}

		> * {
			&:first-child {
				margin-left: 0;
			}

			&:last-child {
				margin-right: 0;
			}
		}

		> img {
			filter: invert(1);
		}

		&.has_label {
			height: 3.8vw;
			padding: 1.3vw 1.94vw;
			position: relative;
			margin: 0 0 0 1.3vw;

			span {
				width: 0.8vw;
			}

			& > img {
				width: 1.25vw;
			}
		}
	}
}
