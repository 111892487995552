@import '../../../assets/styles/mixins';

.channels_menu {
	@include smooth-transition;
	background-image: linear-gradient(to bottom, black, rgba(0, 0, 0, 0));
	overflow: hidden;
	padding-bottom: 0.5vw;
	padding-top: 2.76vw;
	position: absolute;
	transition-duration: 0.5s;
	transition-property: transform;
	z-index: 7;

	> div {
		margin-bottom: 0;
	}

	&.hidden {
		transform: translate3d(0, -100%, 0);
	}
}
