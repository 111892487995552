.login_screen {
	background-image: url(../../../assets/images/activation_background.png);
	background-repeat: no-repeat;
	background-size: 100%;
	height: 100vh;
	opacity: 1;
	text-align: center;
	top: 0;
	visibility: visible;
	width: 100vw;
}

.login_logo {
	padding: 2vw;
	border-radius: 2.5vw;
	margin-top: 8.88vw;
	width: 14.7vw;
	z-index: 2;
	position: relative;
}

.url_line {
	color: #afafaf;
	font-size: 1.88vw;
	font-weight: normal;
	font-family: 'Roboto', sans-serif;
	letter-spacing: 0;
	line-height: 3.13vw;
	margin-top: 3.1vw;
	z-index: 2;
	position: relative;
	text-align: center;
}

.url {
	color: #f1f1f1;
	font-size: 2.34vw;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	z-index: 2;
	position: relative;
	font-family: 'Roboto', sans-serif;
	letter-spacing: normal;
	line-height: 3.13vw;
	text-align: center;
}

.enter_code_line {
	color: #afafaf;
	font-size: 1.88vw;
	font-weight: normal;
	font-family: 'Roboto', sans-serif;
	letter-spacing: 0;
	line-height: 3.13vw;
	text-align: center;
	margin-top: 1.72vw;
	z-index: 2;
	position: relative;
}

.domain {
	color: rgb(255, 255, 255);
	position: fixed;
	bottom: 0.78vw;
	right: 2.6vw;
	text-align: right;
	z-index: 10000;
}

.code_line {
	color: #f1f1f1;
	font-size: 4.69vw;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	font-family: 'Roboto', sans-serif;
	letter-spacing: normal;
	text-align: center;
	z-index: 2;
	position: relative;
}

.enter_siginin_line {
	color: #afafaf;
	font-size: 1.88vw;
	font-weight: normal;
	font-family: 'Roboto', sans-serif;
	letter-spacing: 0;
	line-height: 3.13vw;
	z-index: 2;
	position: relative;
	text-align: center;
}
