@import '../../../assets/styles/mixins';

.menu {
	z-index: 1;
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: center;
	flex: 1;
}

.menu_container {
	display: flex;
	flex-direction: column;
	height: 100vh;
	z-index: 20;
	min-width: 17.083vw;

	.menu_header,
	.menu_footer {
		padding-top: 1.72vw;
		padding-bottom: 1.72vw;
		opacity: 1;
		transition-duration: 0.5s;
		transition-property: opacity;
		@include smooth-transition;
	}

	&.menu_hidden {
		.menu_header,
		.menu_footer {
			opacity: 0;
		}
	}
}

.logo__hidden {
	display: none;
}

.menu__overlay {
	background-color: rgba(0, 0, 0, 0.65);
	top: 0;
	left: 0;
	position: absolute;
	opacity: 1;
	width: 100vw;
	height: 100vh;
	z-index: 5;
	pointer-events: none;
	transition-duration: 0.3s;
	transition-property: opacity;
	z-index: 15;
	@include smooth-transition;

	&.overlay__hidden {
		opacity: 0;
	}
}

.search-active {
	filter: brightness(100%);
}
