/*
 * Replace this with your own classes
 *
 * e.g.
 * .container {
 * }
*/
.container {
	display: flex;
	flex-direction: row;
	// height: 100vh;
	justify-content: center;
	width: 100vw;
}
