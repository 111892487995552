.channel_item {
	align-items: center;
	background-color: var(--grey12);
	border-radius: 0.26vw;
	display: flex;
	width: 11.04vw;
	height: 6.09vw;
	justify-content: center;
	margin-bottom: 0.42vw;

	h5 {
		color: var(--white);
		font-size: 1.35vw;
		font-weight: 500;
	}

	img {
		max-width: 3.91vw;
		max-height: 3.91vw;
	}
}
