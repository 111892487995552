@import '../../../assets/styles/mixins';

.nav-item {
	--padding-vertical: 1.17vw;
	--padding-horizontal: 3.65vw;
	--icon-size: 1.35vw;

	font-family: 'Roboto Medium', 'Roboto', sans-serif;
	color: rgba(241, 241, 241, 0.6);
	font-size: 1.25vw;
	padding: var(--padding-vertical) var(--padding-horizontal);
	padding-right: 0.83vw;
	text-decoration: none;
	width: 100%;
	display: flex;
	white-space: nowrap;

	span {
		margin-left: 2.08vw;
		font-size: 1.25vw;
	}

	.icon {
		width: var(--icon-size);
		height: var(--icon-size);
	}

	.title {
		flex: 1;
		transform-origin: left;
	}

	.title,
	.icon {
		transition: opacity 0.3s 0.1s, transform 0.3s;
		@include smooth-transition;
	}

	.title {
		flex: 1;
		transform-origin: left;
	}

	.title,
	.icon {
		transition: opacity 0.3s 0.1s, transform 0.3s;
		@include smooth-transition;
	}

	&.focus {
		font-family: 'Roboto Bold', 'Roboto', sans-serif;
		color: var(--white-tv);
	}

	&.hidden {
		.title {
			transition: opacity 0.1s, transform 0.3s;
			transform: translateX(-7.4vw);
			opacity: 0;
		}
	}

	&.active {
		color: var(--white-tv);
		font-family: 'Roboto Bold', 'Roboto', sans-serif;
		font-weight: bold;
		position: relative;

		.icon {
			path {
				fill: var(--dn-core-blue);
			}
		}

		&:after {
			position: absolute;
			content: '';
			left: var(--padding-horizontal);
			top: var(--padding-vertical);
			width: var(--icon-size);
			height: var(--icon-size);
			background-image: radial-gradient(var(--dn-core-blue-20), transparent 70%);
			transform: scale(2.5);
		}
	}

	&.active,
	&.focus {
		.icon,
		.title {
			transform: scale3d(1.28, 1.28, 1.28);
		}
	}

	&.active,
	&.focus {
		.icon,
		.title {
			transform: scale3d(1.28, 1.28, 1.28);
		}
	}
}
