.section_nav_item {
	color: var(--grey11);
	display: inline-block;
	font-size: 1.46vw;
	font-weight: 500;
	padding: 0 3vw 1.3vw 0;
	margin-right: 0.64vw;
	position: relative;

	&:before {
		background: rgba(241, 241, 241, 0.4);
		bottom: -0.07vw;
		content: '';
		height: 1px;
		left: 0;
		position: absolute;
		width: calc(100% + 0.64vw);
	}

	&:last-child {
		&:before {
			width: calc(100% - 3vw);
		}
	}

	&.active,
	&.focus {
		color: var(--white-tv);
		opacity: 1;
		position: relative;

		&.focus:after {
			background: #03ffeb;
			border-radius: 0.16vw;
			bottom: -0.16vw;
			content: '';
			height: 0.31vw;
			left: 0;
			position: absolute;
			width: calc(100% - 3vw);
		}
	}
}
