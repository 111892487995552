.add_avatar {
    display: block;
    cursor: pointer;
    width: 9.79vw;
}

.avatar__darken {
    opacity: 0.5;
    width: 9.79vw;
}

.avatar__focus {
    background-color: transparent !important;
    transform: scale3d(1.15, 1.15, 1);
    width: 9.69vw;
}

.container {
    display: inline-block;
    text-align: center;
    margin: 0 1.67vw;
    width: 9.9vw;
/* border: 2px solid green;*/
}

.avatar_title {
    color: #808080;
    font-size: 1.67vw;
    font-weight: normal;
    margin-bottom: 1.67vw;
    overflow: hidden;
    text-align: center;
    text-overflow: ellipsis;
    /* border: 2px solid blue; */
}

.avatar_wrapper{
    margin:0 0 6.9vw 0vw;
}
