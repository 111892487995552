.keyboard_action_button_wrapper {
	align-items: center;
	display: flex;
	height: 3.75vw;
	justify-content: center;

	.keyboard_action_button {
		border: 0.11vw solid #919191;
		color: #919191;
		display: inline-block;
		font: 500 1.35vw/1.39 'Roboto Medium', sans-serif;
		height: 2.29vw;
		letter-spacing: 0.04vw;
		padding: 0.1vw;
		position: relative;
		text-align: center;
		width: 4.17vw;

		&.focus {
			background-color: var(--dn-core-blue);
			border-color: var(--dn-core-blue);
			color: var(--black);
			transform: scale(1.15, 1.15);
		}
	}

	.keyboard_sprite_button {
		background-position: left center;
		background-repeat: repeat-x;
		background-size: 200%;
		display: block;
		height: 3.75vw;
		position: relative;
		width: 4.17vw;

		&.focus {
			background-position: right center;
			opacity: 1;
			transform: scale(1.15, 1.15);
		}
	}
}

.ok_button {
	margin-top: auto;
}
