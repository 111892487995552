.profile__img {
    height: 9.9vw;
    width: 9.9vw;
    -webkit-filter: brightness(50%);
    filter: brightness(50%);
    &__focus {
        background-color: #f1f1f1;
        border-radius: 1vw;
        border: 0.26vw solid #f1f1f1;
        filter: brightness(100%);
        transform: scale3d(1.15, 1.15, 1);
        height: 9.9vw;
        width: 9.9vw;
        &_edit_on {
            background-color: #f1f1f1;
            border-radius: 1vw;
            border: 0.26vw solid #f1f1f1;
            filter: brightness(100%);
            height: 9.9vw;
            width: 9.9vw;
        }
    }
}

.avatar__img{
   display: inline-block;
    filter: brightness(75%);
    height: 9.9vw;
    width: 9.9vw;
    vertical-align: top;

    &__active{
        border-radius: 0.94vw;
        border: solid 0.26vw #c6c6c6;
        transform: scale3d(1.05, 1.05, 1);
        height: 9.9vw;
        width: 9.9vw;
    }

    &__focus {
        filter: brightness(100%);
        text-align: center;
        border: 0.26vw solid #f1f1f1;
        transform: scale3d(1.3, 1.3, 1);
        background-color: #f1f1f1;
        height: 9.9vw;
        width: 9.9vw;
        border-radius: 0.94vw;
    }
}