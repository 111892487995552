@import '../../../assets/styles/mixins';

.channel_logo_block {
	display: inline-block;
	vertical-align: middle;
	filter: brightness(0.6);

	&.active {
		border-radius: 0.286vw;
		border: solid 0.28vh #03ffeb;
		background-color: #000000;
		display: inline-block;
		transform: scale3d(1.1, 1.1, 1.1);
		filter: brightness(1);
		color: #000000;

		.channel_logo {
			border: none;
		}
	}

	.channel_logo {
		width: 9.063vw;
		height: 9.063vw;
		padding: 2.292vw 1.51vw 2.292vw 1.51vw;
		border-radius: 0.26vw;
		border: solid 0.5px rgba(255, 255, 255, 0.6);
		background-color: #111;
		display: inline-block;
		text-align: center;
		display: flex;
		justify-content: center;
		align-items: center;

		img {
			display: inline-block;
			width: 100%;
			z-index: 999;
			position: relative;
			color: rgba(0, 0, 0, 0.7);
		}
	}
}
