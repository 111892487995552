@import '../../../assets/styles/mixins';

.episodes{

    .heading_wrapper{
        background-image: linear-gradient(to top, rgba(1, 0, 0, 0), #000000 57%);
        height: 11.93vw;
        padding: 3.65vw 9.9vw;
        position: absolute;
        width: 100vw;
        z-index: 2;

        h2{
            color: var(--white-tv);
            font-size: 2.6vw;
            font-weight: 700;

            span{
                color: var(--grey11);
                font-weight: 400;
                margin-left: 0.52vw;
            }
        }
    }

    .episode_list_wrapper{
        position: absolute;
        top: 11.93vw;
        z-index: 1;

        .episode_list{
            @include smooth-transition;
            background-color: rgba(0,0,0,0.1);
            height: 100vh;
            position: absolute;
            top: 0;
            transition-duration: 0.5s;
            transition-property: transform;
            width: 100vw;
        
            .episode_wrapper{
                margin-left: 9.95vw;
            }
        }
    }
}