/*
 * Replace this with your own classes
 *
 * e.g.
 * .container {
 * }
*/

.bitrate {
	position: absolute;
	top: 10px;
	right: 10px;
	z-index: 9999;
}

.ad_player {
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;
	top: 0;
	right: 0;
	width: 100vw;
	height: 100vh;
	z-index: 9999;
}

.ad_pause_player {
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;
	top: 0;
	right: 0;
	width: 100vw;
	height: 100vh;
	z-index: 9999;

	img {
		position: relative;
		width: 100%;
		max-width: 100%;
		height: auto;
	}
}
