.textBlock {
  margin-left: 0vw;
}

.mainItem{
    height: 5vw;
    width:100%;
    font-size: 1.6vw;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.54px;
    padding: 0.8vw 0vw 1vw 1vw;
    margin-bottom: 1vw;
  }

  .caption {
    font-size: 1.3vw;
    margin-left: 1.5vw;
    font-weight: 500;
  }
  .focus{
    background-color: #03ffeb;
    color:black;
  }  
  .white {
    color:white;
  }
  .value {
    margin: 0 0 0.278vw 0vw;
  }
 