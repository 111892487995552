.skip_arrow {
	display: flex;
	width: 100%;
	height: 100%;
	align-items: center;
	justify-content: center;
	opacity: 0;

	&.active {
		opacity: 1;
	}
	img {
		height: 3.13vw;
		width: 4.17vw;
	}

	h2 {
		font-size: 3.13vw;
		margin: 0 1.88vw;
	}
}

@keyframes skip_slide_left {
	from {
		opacity: 0;
	}
	50% {
		opacity: 1;
		margin-left: -1.78vw;
	}
	to {
		opacity: 0;
		margin-left: -1.78vw;
	}
}

@keyframes skip_slide_right {
	from {
		opacity: 0;
	}
	50% {
		opacity: 1;
		margin-left: 1.78vw;
	}
	to {
		opacity: 0;
		margin-left: 1.78vw;
	}
}
