.tv_loader__wrapper {
	align-items: center;
	display: flex;
	height: 100vh;
	justify-content: center;
	position: fixed;
	left: 0;
	top: 0px;
	width: 100vw;
	z-index: 16;

	&.overlay {
		background-color: #000;
	}

	.spinner {
		width: 7vw;
		height: 7vw;
	}
}
