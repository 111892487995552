.card_container {
	position: relative;
	width: 10.104vw;
	height: 15.21vw;
	filter: brightness(0.8);
	display: inline-block;
	border-radius: 0.26vw;
	border: 2px solid transparent;

	&.active {
		background-color: #2c2a2a;
		border-color: var(--white-tv);
		transform: scale3d(1.05, 1.05, 1);
		filter: brightness(1);

		.card_playButton {
			opacity: 1;
		}
	}

	.card_overlay {
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: rgba(0, 0, 0, 0);
		transition: background 0.5s ease;
		border-radius: 0.26vw;
		position: absolute;
	}

	.card_playButton {
		filter: drop-shadow(0vw 0.26vw 0.52vw rgba(0, 0, 0, 0.8));
		height: 6.04vw;
		left: 50%;
		opacity: 0;
		position: absolute;
		top: 45%;
		transform: translateX(-50%) translateY(-50%);
		transition: opacity 0.35s ease;
		width: 6.04vw;
	}

	.progress_wrapper {
		height: 0.36vw;
		margin: 0.42vw 0.57vw 0.36vw;
		bottom: 0px;
		left: 0px;
		right: 0px;
		position: absolute;
		transform: translateY(-100%);
	}

	.card_image {
		width: 100%;
		height: 100%;
		border-radius: 0.26vw;
	}
}

.card_progressColor {
	color: #03ffeb;
}
