.button_group {
	display: flex;

	button {
		border-radius: 0vw;
		border-right: 0vw;
		margin-right: 0.05vw;
		text-align: center;
		width: 9.475vw;

		&:first-child {
			border-top-left-radius: 5.21vw;
			border-bottom-left-radius: 5.21vw;
		}

		&:last-child {
			border-top-right-radius: 5.21vw;
			border-bottom-right-radius: 5.21vw;
			border-right: 0.1vw solid var(--white-tv);
		}
	}
}
