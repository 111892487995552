.card_iplate{
    color: var(--white-tv);
    display: inline-block;
    margin: 0 1.04vw;
    opacity: 0.8;
    overflow: hidden;
    position: relative;
    width: 20.16vw;

    .channel_details{
        align-items: center;
        display: flex;
        height: 2.29vw;
        justify-content: center;
        margin: 0.57vw 0;
    
        img{
            max-height: 100%;
        }
    
        .channelNumber{
            font-size: 1.15vw;
            font-weight: bold;
            margin-left: 0.75vw;
        }
    }

    .label_wrapper{
        text-align: center;

        .label{
            display: inline-block;
            background-color: var(--grey12);
            font-size: 1.15vw;
            font-weight: bold;
            padding: 0.21vw 0.63vw;
    
            &.accent{
                background-color: var(--dn-core-blue);
            }
        }
    }

    .card_body{
        background-color: #000;
        border-radius: 0.26vw;
        height: 16.15vw;
        overflow: hidden;

        .img_wrapper{
            position: relative;

            .play_icon{
                position: absolute;
                height: 10.42vw;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                width: 10.42vw;
                z-index: 1;   
            }

            img{
                display: block;
                width: 100%;
            }
        }

        .info_wrapper{
            display: flex;
            flex-direction: column;
            padding: 0.52vw 0.78vw;

            > *{
                margin-bottom: 0.3vw;
            }

            .title,
            .subtitle,
            .synopsis{
                text-overflow: ellipsis;
                overflow: hidden;
            }

            .title{
                font-size: 1.15vw;
                font-weight: bold;
            }
    
            .subtitle{
                font-size: 1.15vw;
            }
    
            .synopsis{
                font-size: 1.25vw;
                height: 5.94vw;
                white-space: normal;
                display: -webkit-box;
                -webkit-line-clamp: 4;
                -webkit-box-orient: vertical;
            }

            span{
                display: inline-block;
                color: var(--grey11);
            }
    
            .progress_wrapper{
                height: 0.21vw;
                width: 100%;
            }
        }
    }

    &.active{
        width: 55.67vw;

        .img_wrapper{
            display: flex;
            flex: 1;

            img{
                width: 28.65vw;
            }
        }

        .card_body{
            display: flex;
            flex-direction: row;

            .info_wrapper{
                display: flex;
                flex: 1;
                padding: 0.78vw 1.56vw;
                width: 45%;

                > *{
                    margin-bottom: 0.4vw;
                }

                .title{
                    font-size: 1.88vw;
                }

                .progress_wrapper{
                    margin-top: 0.5vw;
                }
            }
        }
    }
}