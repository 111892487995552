.episode_list_item {
	display: flex;
	margin-bottom: 2.6vw;
	opacity: 0.5;

	&.focus {
		opacity: 1;

		.episode_image_wrapper {
			border: 0.156vw solid white;
			transform: scaleX(1.2) scaleY(1.2);
			margin-right: 4.16vw;
		}
	}

	.episode_image_wrapper {
		align-items: center;
		border-radius: 0.31vw;
		display: flex;
		flex-direction: column;
		height: 10.52vw;
		justify-content: center;
		margin-right: 2.08vw;
		width: 18.75vw;
		position: relative;
		background-position: center;
		background-size: cover;

		& > img {
			position: absolute;
		}

		.episode_watched {
			position: absolute;
			left: 0.5vw;
			top: 0.5vw;
			display: flex;
			border-radius: 0.16vw;
			padding: 0.16vw 0.24vw;
			background-color: #000;
			align-items: center;
			letter-spacing: 0.02vw;
			font-weight: 300;
			font-size: 0.7vw;

			img {
				height: 0.68vw;
				margin-right: 0.3vw;
			}
		}

		footer {
			position: absolute;
			bottom: 0;
			padding: 0 0.63vw 0 0.63vw;
			width: 100%;

			.episode_label {
				background-color: #000;
				border-radius: 0.16vw;
				padding: 0.12vw 0.2vw;
				right: 0.5vw;
				bottom: 1.2vw;
				position: absolute;
			}

			.progress_wrapper {
				height: 0.2vw;
				bottom: 0.7vw;
				width: 94%;
				position: absolute;
			}
		}
	}

	.episode_info_wrapper {
		color: var(--white-tv);
		display: flex;
		flex-direction: column;
		justify-content: space-between;

		header {
			h3 {
				float: left;
				font-size: 1.56vw;
				font-weight: bold;
				margin-bottom: 0.78vw;
				margin-right: 0.78vw;
			}
			span {
				float: left;
				color: var(--grey11);
				font-size: 1.56vw;
				font-weight: 300;
			}
		}

		p {
			-webkit-box-orient: vertical;
			-webkit-line-clamp: 3;
			clear: both;
			display: -webkit-box;
			font-size: 1.35vw;
			line-height: 1.88vw;
			overflow: hidden;
			width: 42.71vw;
		}

		footer {
			color: var(--grey11);
			font-size: 1.15vw;
		}
	}
}
