.autoplay_popup{
    background-color: var(--grey12);
    border-radius: 0.39vw;
    bottom: 4.1vw;
    max-width: 37vw;
    padding: 1.9vw;
    position: absolute;
    right: 9.4vw;

    .autoplay_header{
        display: flex;
        justify-content: space-between;
        align-items: center;

        p{
            font-weight: bold;
            color: var(--grey11);
        }
    }

    .autoplay_body{
        margin: 0.7vw 0 1.85vw;

        h2{
            font-size: var(--font-lg-2);
        }

        h3{
            font-size: var(--font-md);
            font-weight: 500;
            margin-top: 0.25vw;
        }
    }

    .buttons_wrapper{
        display: flex;
        
        button{
            min-width: 8vw;
            &:last-child {
                margin-left: 1vw;
            }
        }
    }
}