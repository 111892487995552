.progress_bar{
  background-color: rgba(241, 241, 241, 0.4);
  border-radius: 100vw;
  height: 100%;
  width: 100%;
  
  .progress{
    background-color: var(--dn-core-blue);
    border-radius: 100vw;
    height: 100%;
  }
}