.carousel_wrapper {
	margin-bottom: 1.72vw;

	.title {
		margin-left: 4.167vw;
		margin-bottom: 1.5vw;
		font-size: 1.46vw;
		color: var(--grey14);
		letter-spacing: -0.25px;
		position: relative;
		opacity: 1;

		&.active {
			color: var(--white-tv);
		}
	}

	.carousel {
		display: block;
		padding-left: 4.167vw;
		padding-right: 4vw;
		position: relative;
		white-space: nowrap;
		z-index: 1;
		transition: transform 0.2s ease-in-out;

		> div {
			margin: 0vw 0.415vw;

			&:first-child {
				margin-left: 0;
			}

			&:last-child {
				margin-right: 0;
			}
		}
	}
}
