.keyboard_key {
	align-items: center;
	color: #919191;
	display: flex;
	float: left;
	font-size: 2.08vw;
	height: 3.75vw;
	justify-content: center;
	text-align: center;
	width: 3.33vw;

	&.active {
		background-color: var(--dn-core-blue);
		color: var(--black);
		font-size: 2.6vw;
		font-weight: bold;
	}
}
