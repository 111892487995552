.countdown {
    align-items: center;
    background: var(--white);
    border-radius: 50%;
    color: var(--black);
    display: flex;
    font-size: var(--font-md-2);
    font-weight: 700;
    height: 2.73vw;
    justify-content: center;
    width: 2.73vw;
}