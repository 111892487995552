.billboard-mask {
	position: absolute;
	top: 0;
	right: 0;
}

.billboard-mask-fit {
	object-fit: cover;
}

.billboard {
	height: 18vw;
	opacity: 1;
	padding-left: 4.167vw;
	padding-top: 5vw;
	position: relative;
	transition: opacity 0.2s ease-in-out;
	width: 100vw;
	height: 52vh;
	z-index: 0;

	.container-fluid {
		margin: 0vw !important;
		position: relative;
	}

	.billboard_details {
		&.hide {
			visibility: hidden;
		}
	}

	.billboard__inner {
		font-family: 'Roboto Medium', 'Roboto', sans-serif;
		margin-bottom: 2vw;
		max-width: 43.49vw;
	}

	.container {
		margin-bottom: 7%;

		@media only screen and (max-width: 83.33vw) {
			margin-bottom: 9%;
		}
	}

	.header {
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		line-clamp: 1;
		-webkit-line-clamp: 1;
		-webkit-box-orient: vertical;
		font-size: 3.13vw;
		margin-bottom: 0.83vw;
		font-family: 'Roboto Bold', 'Roboto', sans-serif;
		font-weight: bold;
		position: relative;
		z-index: 2;
		height: auto;
	}

	.showLogo {
		width: auto;
		max-width: 480px;
		max-height: 80px;
	}

	.billboard__progress {
		width: 100%;
		margin-top: 0.78vw;
	}

	.synopsisShort {
		font-family: 'Roboto Regular', sans-serif;
		font-size: 1.56vw;
		line-height: 1.5;
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
		margin-bottom: 0.89vw;
	}

	.synopsisFull {
		-moz-osx-font-smoothing: grayscale;
		-webkit-box-orient: vertical;
		-webkit-font-smoothing: antialiased;
		-webkit-line-clamp: 4;
		color: var(--white-tv);
		display: -webkit-box;
		font-family: 'Roboto Regular', 'Roboto', sans-serif;
		font-size: 1.56vw;
		line-clamp: 4;
		line-height: 1.5;
		margin: 0.83vw 0.57vw 1.09vw 0;
		max-width: 39.17vw;
		overflow: hidden;
		position: relative;
		text-overflow: ellipsis;
		text-shadow: 0 0.1vw 0.2vw #000000;
		width: 100%;
	}

	.channelWrap {
		margin-bottom: 1.93vw;
		display: flex;
		align-items: center;
		min-height: 3.28vw;
		margin-bottom: 0.83vw;

		img {
			max-height: 3.28vw;
			max-width: 4.38vw;
			width: auto;
			margin-right: 0.57vw;
			order: 0;
		}

		p {
			font-size: 1.15vw;
			font-family: 'Roboto Regular', sans-serif;
			color: #808080;
			-webkit-font-smoothing: antialiased;
			-moz-osx-font-smoothing: grayscale;
		}
	}

	.channelLogo {
		max-width: 3.5vw;
		width: auto;
		margin-right: 0.46vw;
		margin: 0 0.57vw 0 0;
		text-align: center;

		img {
			width: 100%;
			height: auto;
		}
	}

	.hero_image_wrapper_lrg {
		position: absolute;
		right: 0;
		top: 0;
		width: 69.27vw;
		z-index: -1;
	}

	.hero_image_wrapper {
		height: 38.96vw;
		position: absolute;
		right: 0;
		top: 0;
		width: 69.27vw;
		z-index: -1;
		background-size: 100% auto;
		transition: background-image 0.2s ease-in-out;

		.hero_image {
			border-left: 0.05vw solid var(--black);
			width: 100%;
			height: auto;
		}

		&:before {
			background-image: linear-gradient(to right, var(--black), rgba(0, 0, 0, 0));
			content: '';
			display: inline-block;
			left: -0.05vw;
			position: absolute;
			top: 0;
			width: 36vw;
			bottom: 0;
		}

		&:after {
			background-image: linear-gradient(to top, var(--black), rgba(0, 0, 0, 0));
			bottom: -0.05vw;
			content: '';
			height: 10vw;
			left: 0;
			position: absolute;
			width: 100vw;
		}
	}

	.add_opacity {
		opacity: 0.5;
	}

	.ItemDetails {
		font-weight: bold;
		font-size: 1.56vw;
		line-height: 1.5;
		margin-bottom: 0.89vw;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	}

	.showPage_progress {
		height: 0.42vw;
		border-radius: 0.21vw;
		padding-top: 1.04vw;
	}

	.showPage_progress div {
		height: 0.42vw !important;
		border-radius: 0.21vw;
		max-width: 43.27vw;
	}

	.imageOpacity {
		opacity: 0.1;
	}

	.expiryMessage {
		width: 44.06vw;
		height: 1.3vw;
		margin: 1.25vw 0 0;
		font-family: 'Roboto Regular';
		font-size: 1.15vw;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: normal;
		letter-spacing: normal;
		color: #808080;
	}

	.availableUntil {
		width: 44.06vw;
		height: 1.3vw;
		font-family: 'Roboto Regular';
		font-size: 1.04vw;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: normal;
		letter-spacing: normal;
		color: #808080;
		padding-bottom: 3.33vw;
		margin-top: -2.5vw;
	}

	.color__warning {
		color: #ee0000;
	}

	.cardInfoBillboard {
		position: relative;
		left: 0;
		width: 100vw;
		margin-top: 0px;
		padding-top: 2.81vw;
		margin-left: 0px;
		padding-left: 5vw;
	}

	.item {
		display: inline-block;
		margin-left: 0.75em;
		padding-left: 0.75em;
		border-left: 1px solid rgba(255, 255, 255, 0.7);
		line-height: 1;
		font-size: 1.17vw;

		&:first-of-type {
			padding-left: 0;
			border-left: none;
			margin-left: 0;
		}
	}

	.item__no__left__margin {
		display: inline-block;
		font-size: 1.17vw;
		margin-left: 0;
		padding-left: 0.75em;
		border-left: 1px solid rgba(255, 255, 255, 0.7);
		line-height: 1;

		&:first-of-type {
			padding-left: 0;
			border-left: none;
		}
	}

	.airedInfo {
		display: block;
		color: #808080;
		font-size: 1.15vw;
		margin-bottom: 0.89vw;
	}

	.availDate {
		margin-left: 1.04vw;
		border-left: 0.05vw solid #808080;
		position: relative;
		padding-left: 0.78vw;
		color: #808080;
		font-size: 1.15vw;
	}

	.metadata {
		color: #808080;
		text-transform: uppercase;
		padding: 0;
		display: flex;
		margin: 0;
		margin-bottom: 0.89vw;
	}
}

.progress_wrapper {
	margin: 0.2vw 0vw 2.5vw 0.36vw;
	height: 0.42vw !important;
	border-radius: 0.21vw;
	max-width: 46vw;
}

.bookmark_details {
	font-weight: bold;
	font-size: 1.56vw;
	line-height: 1.5;
	margin-bottom: 0.89vw;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.fadeContainer {
	opacity: 1;
	transition: opacity 0.2s ease-in-out; /* Adjust the duration and timing function as needed */
}

.fade_out_move {
	margin-right: -10px; /* Move 100px to the right */
	transition: all 0.6s;
}

.fade_in_move {
	position: relative;
	margin-right: 0px; /* Move 100px to the right */
	transition: all 0.3s;
}

.fade_out_move_info {
	opacity: 0;
	transition: opacity 0.5s ease-out, transform 0.5s ease-in-out; /* Transition for opacity and transform */
	transform: translate3d(10px, 0, 0); /* Move 100px to the right */
}

.fade_in_move_info {
	opacity: 1;
	transition: opacity 0.3s ease-in, transform 0.3s ease-in-out; /* Transition for opacity and transform */
	transform: translate3d(0px, 0, 0); /* Move 100px to the right */
}

.fade_out_move_info_with_delay {
	opacity: 0;
	transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out; /* Transition for opacity and transform with delay */
	transform: translate3d(10px, 0, 0); /* Move 100px to the right */
}

.fade_out {
	opacity: 0;
	transition: opacity 0.2s ease-in-out; /* Transition for opacity and transform */
}

.fade_in {
	opacity: 1;
	transition: opacity 0.2s ease-in-out; /* Transition for opacity and transform */
}
