@import '../../../assets/styles/mixins';

.section_menu_wrapper {
	background-image: linear-gradient(to bottom, black, rgba(0, 0, 0, 0));
	height: 13.02vw;
	position: absolute;
	transition-duration: 0.5s;
	transition-property: transform;
	white-space: nowrap;
	width: 100%;
	overflow: hidden;
	z-index: 12;
	@include smooth-transition;

	.section_menu {
		@include smooth-transition;
		padding: 2.6vw 3.5vw 1vw 4.167vw;
		position: absolute;
		transition-duration: 0.2s;
		transition-property: transform;
	}

	&.hidden {
		transform: translate3d(0, -100%, 0);
	}
}
