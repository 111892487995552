.option_card {
	color: var(--grey7);
	min-width: 24vw;
	padding: 1.3vw 2.6vw;
	position: relative;
}

.isTVFocus {
	background-color: var(--grey10);
}

.active {
	color: var(--white-tv);
}

.title {
	font-size: 1.56vw;
	margin-bottom: 0;
}

.subtitle {
	font-size: 1.15vw;
	margin-top: 0.8vw;
}

.hide {
	visibility: hidden;
}

.loading_spinner {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	padding: 1vw;
}
