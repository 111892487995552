.iplate{
	transition-property: transform;
    bottom: 0vw;
    margin-bottom: 1.95vw;
    position: absolute;
    transform: translateY(95%);
    transition-duration: 0.5s;

    &.expanded {
        transform: translateY(0%);
    }

    .iplate_menu_wrapper{
        height: 8vw;
        margin-left: -14vw;
        position: relative;
    }
}