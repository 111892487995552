.playerSettingsModal{
  position: absolute;
  left: 0;
  top: 0;
  width:100vw;
  height:100vh;
  opacity:1;
  z-index: 15;
}

.qualityContainer {
  position: absolute;
  right: 0;
  top: 0;
  width: 30vw;
  height: 100%;
  opacity:1;
  -webkit-box-shadow: 0 2.778vw 3.7vw 3.7vw rgba(0, 0, 0, 70%);
  box-shadow: 0 2.778vw 3.7vw 3.7vw rgba(0, 0, 0, 70%);
  background-color: #0e0e0e;
  z-index: 16;
  display:flex;
  align-items: left;
  justify-content: center;
  flex-direction: column;
}
