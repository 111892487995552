.TvSearch_search_grid {
	margin-left: auto;
	top: 2.604vw;
	width: 84.5vw;
	display: flex;
	height: 47.4vw;
	position: relative;
	z-index: 3;

	.keyboard_grid {
		display: block;
		margin-right: 6.458vw;
	}

	.grid_wrapper {
		display: block;
		overflow-y: scroll;
		padding-top: 1vw;
		position: relative;
		width: 100vw;
		color: white;

		.grid {
			align-items: right;
			display: flex;
			flex-wrap: wrap;
			transition: transform 0.2s ease-in-out;

			.results_grid {
				.search_card {
					width: 11.94vw;
					height: 17.03vw;
				}
			}

			.grid_item {
				display: inline-flex;
				padding: 0.625vw;
			}

			.loader_item {
				width: 100vw;
				height: 50vh;
				display: flex;
				justify-content: center;
				align-items: center;
			}
			.text_item {
				width: 100vw;
				height: 100vh;
				display: flex;
				justify-content: center;
				align-items: top;
			}
		}
	}
}
