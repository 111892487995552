.settings_item {
	align-items: center;
	color: var(--white);
	display: flex;
	justify-content: space-between;
	margin-bottom: 2.5vw;
	opacity: 0.65;
	padding: 1.04vw 1.67vw;
	width: 100%;

	&.focus {
		background-color: var(--grey10);
		opacity: 1;
	}

	h3 {
		font-size: 1.875vw;
		font-weight: bold;
		line-height: 2.81vw;
		margin-bottom: 0.52vw;
	}

	h5 {
		font-size: 1.56vw;
	}

	&.show_checkbox {
		padding: 1.04vw 5.21vw;
		margin-bottom: 0.42vw;

		&.checked {
			&:before {
				background-image: url(../../../assets/images/icon_quality_active.png);
				background-repeat: no-repeat;
				background-size: 100%;
			}
		}

		&:before {
			content: '';
			position: absolute;
			width: 1.88vw;
			height: 1.88vw;
			border: solid 0.05vw #919191;
			border-radius: 50%;
			left: auto;
			margin-left: -3.33vw;
			margin-top: 0.52vw;
		}
	}
}
