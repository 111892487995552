.progress_bar {
	background-color: rgba(241, 241, 241, 0.4);
	border-radius: 100vw;
	height: 100%;
	width: 100%;

	.progress {
    background-color: var(--dn-core-blue);
    border-radius: 100vw;
    float: left;
    height: 100%;
	}

	&.focus {
		height: 167%;
	}

  .player_state_indicator {
    background-color: var(--dn-core-blue);
    border-radius: 1.3vw;
    height: 2.6vw;
    position: absolute;
    transform: translateX(-50%) translateY(-35%);
    width: 2.6vw;

    img{
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 1.55vw;
    }
  }
}
