.live_controls_wrapper,
.vod_controls_wrapper {
	position: absolute;
	z-index: 25;
	bottom: 0;
	right: 0;
	top: 0;
	width: 100vw;
	height: 100vh;

	&.visible {
		background-color: rgba(0, 0, 0, 0.5);
	}

	.player_controls {
		bottom: 4vw;
		padding: 0vw 10vw;
		position: absolute;
		width: 100%;

		.metadata_wrapper {
			margin-bottom: 1.66vw;

			h1 {
				font-size: 3.13vw;
				text-shadow: 0 0.1vw 0.2vw rgba(0, 0, 0, 0.5);
			}

			h3 {
				font-size: 1.56vw;
				text-shadow: 0 0.1vw 0.2vw rgba(0, 0, 0, 0.5);
			}
		}

		.times_wrapper {
			display: flex;
			justify-content: space-between;
			margin-bottom: 2vw;

			> div {
				flex: 1;

				&:last-child {
					text-align: right;
				}
			}
		}

		.progress_wrapper {
			height: 0.42vw;
			margin-bottom: 1.09vw;
		}

		.controls_wrapper {
			position: relative;
			display: flex;
			justify-content: space-between;

			> div {
				float: left;
				position: relative;
				min-width: auto;
			}

			.prev_wrapper,
			.next_wrapper {
				display: flex;
				width: 13.65vw;
			}

			.prev_wrapper {
				justify-content: flex-end;
			}

			.controls_center {
				display: flex;
				align-items: center;
				justify-content: center;
				width: 50px;
				position: absolute;
				left: 50%;
				top: 50%;
				transform: translate(-50%, -50%);

				> div {
					margin: 0 1vw;

					&:first-child {
						margin-left: 0;
					}

					&:last-child {
						margin-right: 0;
					}
				}
			}

			.controls_right_vod {
				display: flex;
			}

			.controls_right_livetv {
				display: flex;
				align-items: center;
				justify-content: center;
			}

			.live_dot {
				background-color: white;
				display: inline-block;
				border-radius: 50%;
				height: 0.5vw;
				width: 0.5vw;
				margin-left: -3.75vw;
				margin-right: 0.3vw;
				margin-bottom: 0.1vw;
			}

			.live_indicator {
				background-color: #b50800;
				border-radius: 0.5vw;
				padding: 1.1vw 0.6vw 0vw 4.5vw;
				height: 3.3vw;
				width: 0.83vw;
			}

			.backto_live_dot {
				background-color: white;
				display: inline-block;
				border-radius: 0.5vw;
				height: 0.5vw;
				margin-top: 1.5vw;
				margin-left: 1vw;
				width: 0.5vw;
				position: absolute;
			}
		}

		.buttons_wrapper {
			display: flex;
			align-items: center;
			justify-content: center;
			position: relative;
			margin-top: 1vw;
		}
	}
}

.skip_controls_wrapper {
	display: flex;
	height: 100vh;
	position: absolute;
	width: 100vw;
}
