.profile_name {
    padding: 0px;
    width: 100%;
    text-align: center;
    display: block;
    z-index: 2;
    position: relative;
    white-space: pre-wrap;
    font-size: 2.29vw;
    color: #808080;
    margin:3vw 0 3.39vw 0;

    &.focus {
        color: #f1f1f1;
    }
}