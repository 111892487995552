.avatar_list {
	display: flex;
	flex-direction: row;
	height: 20vw;
	padding: 3.21vw 50vw;
	position: relative;
	white-space: nowrap;
	z-index: 1;
	transition: transform 0.2s ease-in-out;

	> div{
		transform: translateX(-50%);
		margin: 1.56vw;

		&:first-child{
			margin-left: 0;
		}
	}
}