/*
 * Replace this with your own classes
 *
 * e.g.
 * .container {
 * }
*/

.card_app {
    align-items: center;
    background-color: #f1f1f1;
    display: -ms-inline-flexbox;
    display: inline-flex;
    height: 8.59rem;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-pack: center;
    justify-content: center;
    opacity: 1;
    text-align: center;
    width: 14.45rem;
    border-radius: 0.667vw;
    margin: 1.667vw 0.667vw;

    &.active {
        opacity: 1;
        -webkit-transform: scale3d(1.1, 1.1, 1);
        transform: scale3d(1.1, 1.1, 1);
        background-color: #f1f1f1;
    }

    img {
        width: 8.85vw;
        height: 3.18vw;
    }
}